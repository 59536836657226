import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "@shared/seo";
import { Query, SanityRangeConnection } from "@graphql-types";
import { Hero } from "@global";
import Sections from "@shared/sections";
import ProductsSlider from "@components/ranges/productsSlider";
import InsanityPage from "@components/insanity/insanityPage";
import HorrorfirePage from "@components/horrorfire/horrorfirePage";
import BlastercornPage from "@components/blastercorn/blastercornPage";
import Layout from "@shared/layout";
import SnacklesSlider from "@components/snackles/snacklesSlider";

interface Data extends Query {
  altRegions: SanityRangeConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function brandTemplate({ data }: Props) {
  if (data.sanityRange == null) return null;

  const { sanityRange, altRegions } = data;
  const altRegionsOrganised = altRegions.nodes.map(alt => alt.region);

  const { hero, sections, products, slug } = sanityRange;
  const pageIban = sanityRange.region?.iban;

  const insanityPage = sanityRange.slug?.current === "brands/xshot/insanity";
  const horrorfirePage = sanityRange.slug?.current === "brands/xshot/horrorfire";
  const blastercornPage = sanityRange.slug?.current === "brands/xshot/blastercorn";
  const snaklesPage = sanityRange.slug?.current === "snackles";

  return (
    <>
      <SEO
        seoData={sanityRange?.seo}
        slug={sanityRange?.slug?.current as string}
        alternateRegions={altRegionsOrganised}
        currentIban={sanityRange?.region?.iban}
      />

      <Layout page={slug?.current} pageIban={pageIban}>
        <Hero data={hero} useFullHeight />
        {insanityPage && (
          <section className="snap-align-start">
            {" "}
            <InsanityPage data={data} />
          </section>
        )}
        {horrorfirePage && (
          <section className="snap-align-start">
            {" "}
            <HorrorfirePage data={data} />
          </section>
        )}
        {blastercornPage && (
          <section className="snap-align-start">
            {" "}
            <BlastercornPage data={data} />
          </section>
        )}
        {snaklesPage && (
          <section className="snap-align-start">
            <SnacklesSlider data={products} pageIban={pageIban} />
          </section>
        )}
        {!insanityPage && !horrorfirePage && !blastercornPage && !snaklesPage && (
          <section className="snap-align-start">
            <ProductsSlider data={products} slug={slug} />
          </section>
        )}
        {sections && sections.map(section => <Sections key={section?._key} data={section} />)}
      </Layout>
    </>
  );
}

export const query = graphql`
  query Range($slug: String, $iban: String) {
    sanityRange(slug: { current: { eq: $slug } }, region: { iban: { eq: $iban } }) {
      ...sanityRange
    }
    altRegions: allSanityRange(
      filter: { slug: { current: { eq: $slug } }, isHidden: { ne: true } }
    ) {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
