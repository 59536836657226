import React, { useState } from "react";
import { AbsoluteWrapper, Container } from "@util/standard";
import { Maybe, SanityProduct } from "@graphql-types";
import styled from "styled-components";
import { BOORSOK_FONT, FONT_BODY } from "@util/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

import { SnacklesSwiperContainer } from "./SnacklesCarouselStyles";

//@ts-ignore
import { CircleArrow } from "@util/assets";
//@ts-ignore
import { mainData } from "../../locales/dataConst";
interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined;
  pageIban: Maybe<string> | undefined;
}

const NavWrapper = styled(AbsoluteWrapper)`
  display: flex;
  cursor: pointer;
  z-index: 11;
  top: 50%;
  transform: translateY(-50%);
`;

function SnacklesSecondaryCarousel({ data, pageIban }: Props) {
  const [newController, setNewController] = useState<SwiperCore>();
  const [secondaryNewController, setSecondaryNewController] = useState<SwiperCore>();

  const mainPageData = mainData[pageIban];
  const { newItemsData } = mainPageData;

  if (data == null) return null;

  return (
    <SnacklesSwiperContainer>
      <div className="swiper-overlay-text secondary-swiper">{mainPageData.sliderSecondaryTitle}</div>

      {newController && (
        <div style={{ position: "relative" }}>
          <NavWrapper left="3%" onClick={() => newController.slidePrev()}>
            <CircleArrow customArrow flip />
          </NavWrapper>
          <NavWrapper right="3%" onClick={() => newController.slideNext()}>
            <CircleArrow customArrow />
          </NavWrapper>
          <div className="swiper-sub-container">
            <Swiper
              modules={[EffectCoverflow, Controller]}
              onSwiper={setSecondaryNewController}
              controller={{ control: newController }}
              onClick={(swiper: Swiper) => {
                const clickedIndex = swiper.clickedIndex;
                if (clickedIndex !== undefined) {
                  newController.slideTo(clickedIndex);
                }
              }}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1.2}
              spaceBetween={30}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 300,
                modifier: 1,
                slideShadows: false,
              }}
              loop={true}
              loopedSlides={newItemsData.length}
              breakpoints={{
                320: { slidesPerView: 1.5, spaceBetween: 100 },
                576: { slidesPerView: 1.5 },
                768: { slidesPerView: 2 },
                1440: { slidesPerView: 2.45 },
              }}
            >
              {newItemsData.map(item => (
                <SwiperSlide key={item.id}>
                  <div className="slide-content">
                    <img src={item.swiperImg} alt={item.name} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      <Container width="100%">
        <Swiper
          modules={[Controller]}
          onSwiper={setNewController}
          controller={{ control: secondaryNewController }}
          loop
          slidesPerView={1}
          loopedSlides={newItemsData.length}
        >
          {newItemsData.map(item => (
            <SwiperSlide key={item.id}>
              <div className="secondary-slide-container">
                <div className="slider-title-container">{item.name}</div>
                <div className="slider-text-container">
                  <div>
                    <span className="slider-text-content">{item.bdayTitle}</span>
                    <p className="slider-text-content">{item.bday}</p>
                  </div>
                  <div>
                    <span className="slider-text-content">{item.hobbyTitle}</span>
                    <p className="slider-text-content">{item.hobby}</p>
                  </div>
                  <div>
                    <span className="slider-text-content">{item.starSignTitle}</span>
                    <p className="slider-text-content">{item.starSign}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </SnacklesSwiperContainer>
  );
}

export default SnacklesSecondaryCarousel;
